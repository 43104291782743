// laoder listener
let open = XMLHttpRequest.prototype.open;
XMLHttpRequest.prototype.open = function() {
    showLoaderXHR(this, arguments[1] ?? null);
    // Call the stored reference to the native method
    open.apply(this, arguments);
};

function showLoaderXHR(ctx, request_url)
{
    if (!request_url) return;
    let show_loader = true;

    // check ignore URL
    const ignore_urls = [
        'https://api.mapy.cz/',
        '/order/locations',
        'https://consent.cookiefirst.com',
        'https://www.googletagmanager.com'
    ];
    ignore_urls.map(function (url){
        if (request_url.includes(url)) {
            show_loader = false;
        }
    });
    // if request is valid assign an event listener
    if (request_url && show_loader) {
        let body = document.querySelector('body');
        ctx.addEventListener("loadstart", event => body.classList.add('loader-active'), false);
        ctx.addEventListener("load", event => body.classList.remove('loader-active'), false);
    }
    return;
}